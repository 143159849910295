const extensionsRegistry = {
  ms_todo: {
    id: 'ms_todo',
    name: 'Microsoft To Do (beta)',
    kind: 'tasks',
    logo: '/images/ms_todo.svg',
    disclaimers: null,
    // [
    //   'Due to limitations of Microsoft API moving Microsoft ToDo tasks between lists is problematic - it is not supported in Trevor AI and may lead to side effects when done in MS ToDo app.',
    // ],
    capabilities: {
      move_task: false,
      webhook: true,
    },
  },
  google_tasks: {
    id: 'google_tasks',
    name: 'Google Tasks (beta)',
    kind: 'tasks',
    logo: '/images/google_tasks.png',
    disclaimers: [
      'Due to limitations of Google API some features of Google Tasks are not supported in Trevor AI.',
      'Task Due date/time is limited to only date, without time.',
      'Recurring Google Tasks cannot be recognised and will not work correctly after completion.',
      "Subtasks are shown as separate tasks due to Trevor's current limitations.",
    ],
    capabilities: {
      move_task: true,
      webhook: false,
      reorder_tasks: false, // they have subtasks and reorder could mess things up
    },
  },
};

export const getExtensionsRegistry = () => extensionsRegistry;
