import React, { useEffect, useState, memo, useMemo, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import * as utils from 'common/utils';
import { desktopScreen } from 'common/utils';
import { integrationIcon, ListNameWithIcon } from 'components';
import Analytics from '../../analytics.jsx';
import { TaskInputWithAutoComplete } from './TaskInputWithAutoComplete';
import ScrollIntoViewOnMount from '../../components/ScrollIntoViewOnMount.jsx';
import { Notification } from 'components/NotificationsDock.jsx';
import { Note, note2text, Draggable, IconMenu } from '../../components';
import * as actions from '../../actions';
import * as taskOps from '../../operations/task';
import * as sharingOps from '../../operations/sharing';
import { searchStringToRegex } from 'reducers/ui';
import {
  getFilters,
  getTasksForNextXDays,
  getTasksForScheduleQueue,
  getOverdueTasks,
  applyVisibilityFilters,
} from 'reducers/tasks';
import MediaQuery from 'react-responsive';
import { AddNewTaskPanel } from '../../components/AddNewTaskPanel';
import { UserScheduleZonesButton } from 'modules/settings/UserScheduleZonesDropdown';
import { EnabledCalendarsButton } from 'modules/settings/EnabledCalendarsDropdown';
import { PremiumModalDock } from 'premium.jsx';
import * as listOps from '../../operations/list';

import {
  Button,
  Paper,
  Box,
  Toolbar,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Divider,
  TextField,
  Chip,
  Badge,
  Tooltip,
  Stack,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Typography,
} from '@mui/material';

// All Icon Names - https://material.io/icons/
import ActionSearch from '@mui/icons-material/Search';
import PlaylistAdd from '@mui/icons-material/PlaylistAdd';
import ActionEvent from '@mui/icons-material/Event';
import ToggleCheckBox from '@mui/icons-material/CheckBox';
import NavigationClose from '@mui/icons-material/Close';
import InboxIcon from '@mui/icons-material/Inbox';
import ArrowBackward from '@mui/icons-material/ChevronLeft';
import ArrowForward2 from '@mui/icons-material/ChevronRight';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import {
  History,
  Lock,
  List,
  PlayArrow,
  InsertLink,
  HelpOutline,
  Lightbulb,
  OpenInNew,
  Settings,
  People,
  Add,
  CalendarToday,
  Event,
  EventAvailable,
  Checklist,
  ListAlt,
} from '@mui/icons-material';

import {
  getVisibleTasksWithLists,
  getTasksForList,
  getEnabledLists,
  getAllLists,
  getPendingInvitations,
} from 'reducers/tasks';
import { SortableList } from '../../components/SortableList';
import { ListOptions } from '../../components/ListOptions';
import { InboxFilters } from '../../components/InboxFilters';
import { TaskTitleWithControls } from './TaskTitleWithControls';
import { TaskCard } from './TaskCard';
import { BorderlessSelect } from '../../components/BorderlessSelect';
import { ListsSettings, ShareWithPeopleDialog } from '../lists';

import { theme } from '../../theme';
import { useTheme } from '@mui/material/styles';
import { DesktopOnlyBottomNotch } from '../../components/DesktopOnlyBottomNotch.jsx';
import { PostponePlaceholder } from './PostponePlaceholder';

import { usePredictDuration } from '../../operations/ml';
import { CenteredSpinner } from '../../components/CenteredSpinner';
import { SmartSchedulingIcon, SchedulingSuggestionsIcon } from 'components/CustomIcons.jsx';
import { AutoScheduler } from './AutoScheduler';
import styled from '@emotion/styled';
import { isDueDateWithTime, getExtensionsRegistry } from 'shared';

export * from './TaskInputWithAutoComplete';
export * from './TaskTitleWithControls';
export * from './QuickSuggestions';
export * from './SmartSuggestions';
export * from './TaskCard';

const { $ } = window;
const DEVELOPMENT_ENV = process.env.NODE_ENV === 'development';

export const isOverdue = utils.isOverdue;

const sortByItemOrder = (l, r) => +l.item_order - r.item_order;

const sortByOverdueFirstAndItemOrder = (l, r) => {
  // const today = moment().startOf('day')
  // we check overdue by scheduled END date
  const lOverdue = isOverdue(l),
    rOverdue = isOverdue(r);

  if (!lOverdue && !rOverdue) return sortByItemOrder(l, r);
  // we sort them by scheduled BEGIN date
  if (lOverdue && rOverdue) return moment(l.eventBeginDate).isBefore(r.eventBeginDate) ? -1 : 1;
  if (lOverdue) return -1;
  if (rOverdue) return 1;
};

const highlight = (container, element) => {
  $(container).addClass('animated flash-pulse');
  $(element).addClass('animated levitate');
  setTimeout(() => {
    $(container).removeClass('animated flash-pulse');
    $(element).removeClass('animated levitate');
  }, 4000);
};

const _formatDueScheduledDate = (date) => {
  date = moment(date).startOf('day');
  let daysFromToday = date.diff(moment().startOf('day'), 'days');
  let sameYear = date.year() === moment().year();
  switch (daysFromToday) {
    case 0:
      return 'Today';
    case 1:
      return 'Tomorrow';
    case -1:
      return 'Yesterday';
    default:
      return moment(date).calendar({
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: '[last] dddd',
        nextWeek: 'dddd',
        sameElse: sameYear ? 'D MMM' : 'L',
      });
    // return date.format('L');
  }
};

export const formatDueScheduledDate = (date) => {
  const _date = _formatDueScheduledDate(date);
  const hasTime = typeof date === 'string' && isDueDateWithTime(date);
  if (hasTime) {
    return _date + ' ' + moment(date).format('LT');
  }
  return _date;
};

export const formatDuration = (duration) => {
  // duration = minutes as integer
  switch (true) {
    // case duration === 0:
    //   return 'All-day';

    case duration < 60:
      return moment.utc(moment.duration(duration, 'minutes').asMilliseconds()).format('m') + 'min';

    case duration < 60 * 24 && duration % 60 === 0:
      return moment.utc(moment.duration(duration, 'minutes').asMilliseconds()).format('H[h]');

    case duration < 60 * 24:
      return moment.utc(moment.duration(duration, 'minutes').asMilliseconds()).format('H[h] m[m]');

    case duration == 60 * 24:
      return 'All day';

    case duration >= 60 * 24 && duration % (60 * 24) === 0:
      return moment.duration(duration, 'minutes')._data.days + ' days'; // .asMilliseconds doesn't work. Perhaps too big for utc.

    // case duration >= (60 * 24):
    //   return moment.duration(duration, "minutes")._data.days + ' days ' + moment.duration(duration, "minutes")._data.hours + 'h'

    default:
      return moment.duration(duration, 'minutes').humanize();
  }
};

const DraggableWithTaskProps = React.memo((props) => {
  let { task, analiticsHint, onTouchDrag, onTouchDragStop } = props;

  const { topPrediction: prediction } = usePredictDuration(task);

  return (
    <Draggable
      sx={{ borderRadius: 2 }}
      dataProp="event"
      data={!task.eventId ? utils.createEventFromTask(task, prediction) : utils.rescheduleEventFromTask(task)}
      // denyDrag={!!task.eventId}
      dragClass={'task-in-move'}
      onDrag={() =>
        analiticsHint && Analytics.event({ category: 'Scheduling', action: 'Dragged Task from ' + analiticsHint })
      }
      onDragDenied={() =>
        analiticsHint &&
        Analytics.event({ category: 'Scheduling', action: 'Attempt to Drag Scheduled Task from ' + analiticsHint })
      }
      onTouchDrag={onTouchDrag}
      onTouchDragStop={onTouchDragStop}
      {...props}
    />
  );
});

const SortableItemWithTaskProps = React.memo(({ task, children, index }) => {
  const { topPrediction: prediction } = usePredictDuration(task);
  const data = !task.eventId ? utils.createEventFromTask(task, prediction) : utils.rescheduleEventFromTask(task);

  return (
    <li
      id={index}
      ref={(el) => {
        $(el).data('event', data);
      }}
    >
      <Box elevation={0}>{children}</Box>
    </li>
  );
});

const __AggregatedTasksList = (props) => {
  const { searchRegex, showDayLabel, listsObj, showSuggestionForTaskId, autoSuggestMode, dispatch } = props;
  const tasks = useMemo(
    () =>
      props.tasks.filter((task) => {
        if (!searchRegex) return true;
        return (
          searchRegex.test(task.title) ||
          searchRegex.test('#' + listsObj[task.listId]?.name) ||
          (task.note && searchRegex.test(note2text(task.note)))
        );
      }),
    [props.tasks, searchRegex, listsObj]
  );

  // Group tasks by day label
  const tasksByDayLabel = tasks.reduce((acc, task) => {
    const dayLabel = task.dueDate ? moment(task.dueDate).format('dddd, MMM Do') : 'No Due Date';
    if (!acc[dayLabel]) {
      acc[dayLabel] = [];
    }
    acc[dayLabel].push(task);
    return acc;
  }, {});

  const autoScheduleTasks = useMemo(
    () => tasks.filter((t) => !t.completed && !t.eventId && !t.recurringEventIds),
    [tasks]
  );
  const totalTasksCount = autoScheduleTasks.length;
  const overdueTasksCount = useMemo(() => tasks.filter((task) => isOverdue(task)).length, [tasks]);
  useEffect(() => {
    dispatch(actions.setCurrentViewTasksCounts(overdueTasksCount, totalTasksCount));
  }, [dispatch, overdueTasksCount, totalTasksCount]);

  return (
    <Box
      id="inbox"
      className="tasks-panel-tab-content"
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 48px)', // Toolbar height
      }}
    >
      <Box
        className="tasks-panel-tab-content-container with-task-panel"
        sx={{
          height: '100%',
          paddingTop: '5px',
          overflow: 'hidden auto',
          overflowScrolling: 'touch',
        }}
      >
        {autoSuggestMode && !showSuggestionForTaskId && <AutoScheduler tasks={autoScheduleTasks} />}

        {Object.entries(tasksByDayLabel).map(([dayLabel, tasks]) => (
          <React.Fragment key={dayLabel}>
            {showDayLabel && <div className="day-label">{dayLabel}</div>}
            <SortableList
              disableSorting
              dragAndDropTaskScheduling
              onDragStart={() => dispatch(actions.changeActiveView(1))}
            >
              {tasks.map((task) => {
                const showSuggestion = showSuggestionForTaskId === task.id;
                let list = listsObj[task.listId];
                return (
                  <ScrollIntoViewOnMount key={task.id} activate={showSuggestion}>
                    <TaskCard
                      hintExpand={showSuggestion}
                      onToggleExpand={(isExpand) => {
                        if (!isExpand) {
                          dispatch(actions.clearShowSuggestionsForTaskId(task.id));
                        } else {
                          dispatch(actions.setShowSuggestionsForTaskId(task.id));
                        }
                      }}
                      task={task}
                      list={list}
                      {...props}
                      showList={props.showList}
                      onListClick={props.onListClick}
                      renderPreview={({ onToggleExpand, onShowTaskOptions, taskOptions }) => {
                        return (
                          <DraggableWithTaskProps
                            key={task.id}
                            task={task}
                            analiticsHint="AggregatedTasksList"
                            onTouchDrag={taskOps.dragTask}
                            onTouchDragStop={() => taskOps.dragTask(false)}
                          >
                            <TaskTitleWithControls
                              task={task}
                              expandToggle={() => {
                                onToggleExpand();
                                Analytics.event({
                                  category: 'AggregatedTasksList',
                                  action: 'Suggestion box toggle',
                                });
                              }}
                              showSuggestion={showSuggestion}
                              showTaskOptions={() => {
                                onShowTaskOptions();
                              }}
                              listColor={list.color}
                              taskOptions={taskOptions}
                            />
                          </DraggableWithTaskProps>
                        );
                      }}
                    />
                  </ScrollIntoViewOnMount>
                );
              })}
            </SortableList>
          </React.Fragment>
        ))}
      </Box>
      <DesktopOnlyBottomNotch />
    </Box>
  );
};
const AggregatedTasksListWithMaxDueDate = connect(
  (state, ownProps) => {
    return {
      tasks: getTasksForNextXDays(state, ownProps.due, ownProps.after),
      listsObj: state.tasks.listsObj,
      searchRegex: searchStringToRegex(state),
      showSuggestionForTaskId: state.ui.showSuggestionForTaskId,
      autoSuggestMode: state.calendar.autoSuggestMode,
      showList: true,
      showDayLabel: true,
    };
  },
  (dispatch) => ({
    onListClick: (list) => dispatch(actions.selectList(list.id)),
    dispatch,
  })
)(__AggregatedTasksList);

const AggregatedTasksListWithScheduleQueue = connect(
  (state) => {
    return {
      tasks: getTasksForScheduleQueue(state),
      listsObj: state.tasks.listsObj,
      searchRegex: searchStringToRegex(state),
      showSuggestionForTaskId: state.ui.showSuggestionForTaskId,
      autoSuggestMode: state.calendar.autoSuggestMode,
      showList: true,
      showDayLabel: true,
    };
  },
  (dispatch) => ({
    onListClick: (list) => dispatch(actions.selectList(list.id)),
    dispatch,
  })
)(__AggregatedTasksList);

const constArr = [];

const AggregatedFilteredTasksList = ({ filter }) => {
  const query = filter.query;
  const tasksObj = useSelector((state) => state.tasks.tasksObj);
  const listsObj = useSelector((state) => state.tasks.listsObj);
  const searchRegex = useSelector(searchStringToRegex);
  const { showSuggestionForTaskId, filter: visibleTasksFilter } = useSelector((state) => state.ui);
  const { autoSuggestMode } = useSelector((state) => state.calendar);
  const [todoistAccessToken, todoistLang] = useSelector((state) => [
    state.account.user.accounts?.todoist?.accessToken,
    // state.account.user.accounts?.todoist?.syncToken,
    state.account.user.accounts?.todoist?.lang,
  ]);

  const enabledLists = useSelector(getEnabledLists);

  const dispatch = useDispatch();
  const onListClick = (list) => dispatch(actions.selectList(list.id));

  const [taskIds, setTaskIds] = useState(null);
  const [forceRefreshIdx, setForceRefreshIdx] = useState(0);

  useEffect(() => {
    const onFocusChange = () => {
      if (document.hidden) return;
      console.log('onFocusChange, refresh task ids');
      setForceRefreshIdx((idx) => idx + 1);
    };
    console.log('add focus event listener');
    document.addEventListener('visibilitychange', onFocusChange);
    return () => {
      console.log('remove focus event listener');
      document.removeEventListener('visibilitychange', onFocusChange);
    };
  }, [setForceRefreshIdx]);

  useEffect(() => {
    import(/* webpackChunkName: "todoistApi" */ '../../api/todoist.ts')
      .then((todoistApi) => {
        setTaskIds(null);
        return todoistApi.loadTasksIdsWithFilter(todoistAccessToken, query, todoistLang);
      })
      .then((taskIds) => {
        setTaskIds(taskIds);
      })
      .catch((err) => {
        setTaskIds(constArr);
        console.error('err', err);
        Notification.showError('Error loading tasks from Todoist');
      });
  }, [query, todoistAccessToken, todoistLang, forceRefreshIdx]);

  const tasks = useMemo(() => {
    if (!taskIds) return constArr;
    const setEnabledListIds = new Set(enabledLists.map((list) => list.id));
    const tasks = taskIds
      .map((id) => tasksObj[`${id}@TrevorOrig:2`])
      .filter((t) => !!t && setEnabledListIds.has(t.listId));

    return applyVisibilityFilters(tasks, visibleTasksFilter);
  }, [enabledLists, visibleTasksFilter, taskIds, tasksObj]);

  if (!taskIds) {
    return <CenteredSpinner />;
  }

  return (
    <__AggregatedTasksList
      tasks={tasks}
      listsObj={listsObj}
      searchRegex={searchRegex}
      showSuggestionForTaskId={showSuggestionForTaskId}
      autoSuggestMode={autoSuggestMode}
      showList
      onListClick={onListClick}
      dispatch={dispatch}
    />
  );
};

class __ListsWithTasks extends React.PureComponent {
  constructor() {
    super();
    this.state = { expandedLists: {} };
  }
  render() {
    DEVELOPMENT_ENV && console.log('ListsWithTasks - render');
    const {
      onSelectList,
      user,
      filter,
      searchRegex,
      listsObj,
      enabledLists,
      showSuggestionForTaskId,
      forceShowTaskId,
      dispatch,
    } = this.props;
    let filteredTasks = applyVisibilityFilters(this.props.tasks, filter, undefined, user)
      .filter((task) => {
        if (!searchRegex) return true;
        return (
          searchRegex.test(task.title) ||
          searchRegex.test('#' + listsObj[task.listId]?.name) ||
          (task.note && searchRegex.test(note2text(task.note)))
        );
      })
      .sort((l, r) => (l.created < r.created ? 1 : -1));

    let tasksByListID = {};
    filteredTasks.forEach((task) => {
      let list = tasksByListID[task.listId] || [];
      list.push(task);
      tasksByListID[task.listId] = list;
    });

    // const selectList = list => onSelectList(list.id)

    const expandList = (list) =>
      this.setState((state) => {
        let expandedLists = { ...state.expandedLists };
        expandedLists[list.id] = (expandedLists[list.id] || 0) + 10;
        return { expandedLists };
      });

    const { expandedLists } = this.state;

    const mapLists = (callbackListTasks) => {
      let ret = [];
      for (let list of enabledLists) {
        if (!tasksByListID[list.id]) continue;

        let showTasksCount = expandedLists[list.id] || 3;
        let listTasks = tasksByListID[list.id].sort(sortByOverdueFirstAndItemOrder);
        let tasksToShow = listTasks.slice(0, showTasksCount);
        let newTasks = listTasks.slice(showTasksCount).filter((tt) => tt.id === forceShowTaskId);
        ret.push(callbackListTasks(list, [...tasksToShow, ...newTasks], tasksToShow.length < listTasks.length));
      }
      return ret;
    };

    DEVELOPMENT_ENV && console.log('ListsWithTasks - before final render');
    return (
      <div id="inbox-content">
        {mapLists((list, tasks, hasMore) => (
          <div key={list.id} className="inbox-list">
            <div
              className="inbox-list-info"
              style={{
                display: 'inline-flex',
                width: '100%',
                marginTop: '40px',
                gap: '2px',
                paddingBottom: '2px',
              }}
            >
              <Button
                variant="text"
                className="inbox-list-title"
                startIcon={integrationIcon(list)}
                endIcon={
                  list.shared ? (
                    <ShareIcon style={{ color: list.color, height: 20, width: 20, marginLeft: '3px' }} />
                  ) : (
                    ''
                  )
                }
                onClick={(e) => {
                  e.preventDefault();
                  onSelectList(list.id);
                }}
                sx={{
                  flexGrow: 1,
                  justifyContent: 'flex-start',
                  color: list.color,
                  fontSize: '1.3em',
                  fontWeight: '300',
                  textTransform: 'none',
                  textAlign: 'left',
                  padding: '0 16px',
                }}
              >
                {list.name}
              </Button>
              <Tooltip
                title={list.queue ? 'Remove List from Scheduling Queue' : 'Add List to Scheduling Queue'}
                placement="top"
                disableInteractive
              >
                <IconButton
                  onClick={() => {
                    if (
                      !list.queue ||
                      window.confirm(
                        `Are you sure you want to remove #${list.name} from the scheduling queue? You will no longer see scheduling suggestions for those tasks in your timeline. Tasks individually added to the Scheduling Queue will not be removed. You can always add the list again at any time.`
                      )
                    ) {
                      taskOps.toggleListShedulingQueue(list);
                    }
                  }}
                >
                  <SmartSchedulingIcon color={list.queue ? list.color : theme.palette.faded.main} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Add Task in List" placement="top" disableInteractive>
                <IconButton
                  sx={{
                    color: list.color,
                    width: '36px',
                    height: '36px',
                    alignSelf: 'flex-end',
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    this.props.onAddTaskToList(list);
                    Analytics.event({ category: 'Inbox', action: 'Selected List from Inbox Button' });
                    setTimeout(() => {
                      document.querySelector('.add-new-task-panel-input input').focus();
                    }, 100);
                  }}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
            <SortableList
              disableSorting
              dragAndDropTaskScheduling
              onDragStart={() => dispatch(actions.changeActiveView(1))}
            >
              {tasks.map((task) => {
                const showSuggestion = showSuggestionForTaskId === task.id;

                return (
                  <ScrollIntoViewOnMount key={task.id} activate={showSuggestion}>
                    <TaskCard
                      task={task}
                      list={list}
                      hintExpand={showSuggestion}
                      onToggleExpand={(isExpand) => {
                        if (!isExpand) {
                          dispatch(actions.clearShowSuggestionsForTaskId(task.id));
                        } else {
                          dispatch(actions.setShowSuggestionsForTaskId(task.id));
                        }
                      }}
                      renderPreview={({ onToggleExpand, onShowTaskOptions, taskOptions }) => {
                        return (
                          <DraggableWithTaskProps
                            key={task.id}
                            task={task}
                            analiticsHint="Inbox"
                            onTouchDrag={taskOps.dragTask}
                            onTouchDragStop={() => taskOps.dragTask(false)}
                            style={{ cursor: 'grab !important' }}
                          >
                            <TaskTitleWithControls
                              task={task}
                              expandToggle={() => {
                                onToggleExpand();
                                Analytics.event({
                                  category: 'Task',
                                  action: 'Scheduling Options Toggle',
                                });
                              }}
                              showSuggestion={showSuggestion}
                              showTaskOptions={() => {
                                onShowTaskOptions();
                              }}
                              listColor={list.color}
                              taskOptions={taskOptions}
                            />
                          </DraggableWithTaskProps>
                        );
                      }}
                    />
                  </ScrollIntoViewOnMount>
                );
              })}
            </SortableList>
            {hasMore && (
              <Button
                variant="text"
                onClick={(e) => {
                  e.preventDefault();
                  expandList(list);
                }}
                sx={(theme) => ({ color: theme.palette.info.light, fontWeight: '300', padding: '6px 10px' })}
              >
                <ExpandMore style={{ color: '#afafaf', marginLeft: '4px', marginRight: '10px' }} />
                Show more...
              </Button>
            )}
          </div>
        ))}
        {filteredTasks.length === 0 && (
          <p style={{ margin: '48px 15px 48px 48px' }}>
            <Link to="#" onClick={() => highlight('#add-new-task-panel', '.add-new-task-panel-button')}>
              Add more tasks
            </Link>{' '}
            or <Link to="app/settings">integrate with tools like Todoist</Link>
          </p>
        )}
      </div>
    );
  }
}

const ListsWithTasks = connect(
  (state, props) => ({
    user: state.account.user,
    filter: state.ui.filter,
    searchRegex: searchStringToRegex(state),
    tasks: getVisibleTasksWithLists(state),
    showSuggestionForTaskId: state.ui.showSuggestionForTaskId,
    forceShowTaskId: state.ui.forceShowTaskId,
    listsObj: state.tasks.listsObj,
    enabledLists: getEnabledLists(state),
    onAddTaskToList: props.onAddTaskToList,
  }),
  (dispatch) => ({
    onSelectList: (id) => dispatch(actions.selectList(id)),
    dispatch,
  })
)(__ListsWithTasks);

const Inbox = memo(({ toggleSettings }) => {
  const [addTaskToList, setAddTaskToList] = useState(null);
  if (DEVELOPMENT_ENV) console.log('Inbox - render');

  const {
    autoSuggestMode,
    scheduleQueueTasks,
    overdueTasks,
    inboxSuggestionsLimit,
    overdueSuggestionsLimit,
    showSuggestionForTaskId,
  } = useSelector((state) => {
    const { autoSuggestMode } = state.calendar;
    const scheduleQueueTasks = getTasksForScheduleQueue(state);
    const inboxSuggestionsLimit = state.account.user.prefs.inbox_suggestions_limit || 0;
    const overdueSuggestionsLimit = state.account.user.prefs.overdue_suggestions_limit || 0;
    const overdueTasks = getOverdueTasks(state);
    const showSuggestionForTaskId = state.ui.showSuggestionForTaskId;
    return {
      autoSuggestMode,
      scheduleQueueTasks,
      overdueTasks,
      inboxSuggestionsLimit,
      overdueSuggestionsLimit,
      showSuggestionForTaskId,
    };
  });
  const overdueTasksCount = overdueTasks.length;
  const scheduleQueueTasksCount = scheduleQueueTasks.length;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setCurrentViewTasksCounts(overdueTasksCount, scheduleQueueTasksCount));
  }, [dispatch, overdueTasksCount, scheduleQueueTasksCount]);

  return (
    <Box
      id="inbox"
      className="tasks-panel-tab-content"
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 48px)', // Toolbar height
      }}
    >
      <Box
        className="tasks-panel-tab-content-container with-task-panel"
        sx={{
          height: '100%',
          overflow: 'hidden auto',
          overflowScrolling: 'touch',
        }}
      >
        {/*<AggregatedTasksList {...this.props} tasks={taskList}/>*/}
        <ListsWithTasks onAddTaskToList={setAddTaskToList} />
        <InboxHelper toggleSettings={toggleSettings} />
        {!!showSuggestionForTaskId ? (
          <></> /*noop*/
        ) : autoSuggestMode &&
          overdueTasksCount > 0 &&
          overdueSuggestionsLimit > 0 &&
          !utils.isOverdueSuggestionsSnoozed() ? (
          <AutoScheduler tasks={overdueTasks} limit={overdueSuggestionsLimit} overdueMode />
        ) : (
          autoSuggestMode &&
          !!inboxSuggestionsLimit && <AutoScheduler tasks={scheduleQueueTasks} limit={inboxSuggestionsLimit} />
        )}
      </Box>
      <MediaQuery query={desktopScreen}>
        <AddNewTaskPanel
          requestedListId={addTaskToList?.id}
          onUnselectList={() => setAddTaskToList(null)}
          // onAdd={() => setAddTaskToList(null)}
        />
      </MediaQuery>
    </Box>
  );
});

const InboxHelperStyles = {
  display: 'inline-flex',
  boxSizing: 'border-box',
  alignItems: 'center',
  width: '100%',
  gap: '10px',
  borderRadius: '8px',
  padding: '0 8px',
  backgroundColor: theme.inbox.helperBackground,
  minHeight: '44px !important', // overrides expanded state
  flexWrap: 'wrap',
  color: theme.inbox.helperText,
  cursor: 'pointer',
  textDecoration: 'none',
  boxShadow: '0px 1px 3px rgba(0,0,0,0)',
  fontSize: '1em',
  lineHeight: '1.5em',
  fontWeight: '500',
  p: {
    fontSize: '1em',
    lineHeight: '1.5em',
    margin: '0 !important',
    fontWeight: '500',
  },
  '.MuiSvgIcon-root': {
    color: theme.inbox.helperText,
  },
};

const InboxHelperAccordion = ({ icon, text, iconRight = '', children }) => {
  return (
    <Accordion
      sx={{
        backgroundColor: 'transparent',
        margin: '0 !important', // overrides expanded state
        boxShadow: 'none',
        '&:before': { backgroundColor: 'transparent' },
      }}
    >
      <AccordionSummary
        sx={{
          ...InboxHelperStyles,
          borderRadius: '8px !important',
          '&:hover': { boxShadow: '0px 1px 3px rgba(0,0,0,0.25)' },
          '.MuiAccordionSummary-content': {
            margin: '0 !important',
            alignItems: 'center',
          },
        }}
        expandIcon={iconRight}
      >
        {icon}
        <p style={{ margin: '0 0 0 10px' }}>{text}</p>
      </AccordionSummary>
      <AccordionDetails
        sx={{ padding: '4px 0 0 0', display: 'flex', flexDirection: 'column', gap: '4px', marginLeft: '32px' }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

const InboxHelperLink = ({ icon, text, iconRight = '', onClick = null, to = '#' }) => {
  const theme = useTheme();
  return (
    <Link
      to={to}
      onClick={onClick}
      style={InboxHelperStyles}
      onMouseEnter={(e) => {
        e.currentTarget.style.boxShadow = '0px 1px 3px rgba(0,0,0,0.25)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.boxShadow = '0px 1px 3px rgba(0,0,0,0)';
      }}
    >
      {icon}
      <p style={{ margin: '10px 0' }}>{text}</p>
      <Box sx={{ flexGrow: 1 }} />
      {iconRight}
    </Link>
  );
};

class InboxHelper extends React.Component {
  render() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          padding: '2px 6px 86px 6px',
          gap: '4px',
        }}
      >
        <img
          src="/images/scheduling-hint.svg"
          alt="Schedule a task with auto-schediling or drag and drop"
          style={{ display: 'block', margin: '0 auto' }}
        />
        <InboxHelperAccordion icon={<Checklist />} text="Basic Setup Checklist" iconRight={<ExpandMore />}>
          <InboxHelperLink icon={<InsertLink />} text="Sync your Google/Outlook Calendars" to="/app/settings" />
          <InboxHelperLink icon={<CalendarToday />} text="Set your Scheduling Calendar" to="/app/calendar_options" />
          <InboxHelperAccordion icon={<EventAvailable />} text="Enable Scheduling Calendar on your Devices">
            <p>
              Open your native calendar apps on your devices and make sure the Scheduling Calendar is enabled. You will
              be able to view your scheduled tasks anywhere you are and receive timely notifications. You can later
              assign a different scheduling calendar for each list.
            </p>
          </InboxHelperAccordion>
          <InboxHelperLink
            icon={<PlayArrow />}
            text="Watch the Complete Task Planning Guide"
            iconRight={<OpenInNew />}
            onClick={() => window.open('https://www.youtube.com/watch?v=C5NWC7QMVOg', '_blank')}
          />
        </InboxHelperAccordion>
        <InboxHelperLink
          icon={<ListAlt />}
          text="Add and Manage Task Lists"
          iconRight={<PlaylistAdd />}
          onClick={this.props.toggleSettings}
        />
        <InboxHelperLink
          icon={<InsertLink />}
          text="Sync Tasks from Other Apps"
          iconRight={<Settings />}
          to="/app/settings"
        />
        <InboxHelperLink
          icon={<PlayArrow />}
          text="AI Scheduling Video Guide"
          iconRight={<OpenInNew />}
          onClick={() => window.open('https://youtu.be/9q3STBdRdZY?si=G3MPrr-ZetmQcZ0i&t=14', '_blank')}
        />
        <InboxHelperAccordion icon={<HelpOutline />} text="Can&#39;t find your tasks?" iconRight={<ExpandMore />}>
          <InboxHelperLink
            icon={<PlaylistAdd />}
            text="Make sure the Task List is Enabled"
            onClick={this.props.toggleSettings}
          />
          <InboxHelperLink
            icon={<ActionEvent />}
            text="Enable showing Scheduled Tasks"
            onClick={() => highlight('#tasks-panel-tabs', '.task-panel-tabs-scheduled-completed-filter')}
          />
          <InboxHelperLink
            icon={<ToggleCheckBox />}
            text="Enable showing Completed Tasks"
            onClick={() => highlight('#tasks-panel-tabs', '.task-panel-tabs-scheduled-completed-filter')}
          />
        </InboxHelperAccordion>
        <InboxHelperAccordion icon={<Lightbulb />} text="Pro Tips" iconRight={<ExpandMore />}>
          <InboxHelperLink
            icon={<CalendarToday />}
            text="Declutter your schedule by disabling calendars"
            to="/app/calendar_options"
          />
          <InboxHelperLink
            icon={<People />}
            text="Invite peers to collaborate on task lists"
            onClick={this.props.toggleSettings}
          />
          <InboxHelperLink icon={<Settings />} text="Set your Scheduling Preferences" to="/app/settings" />
          <InboxHelperLink icon={<Settings />} text="Define your Scheduling Blocks" to="/app/settings" />
          <InboxHelperAccordion icon={<Add />} text="Add to Homescreen on mobile via Share button">
            <p>
              Tap the Menu icon in your device's browser and select "Install App" or "Add to Homescreen". Trevor AI will
              be instantly accessible from your Home Screen.
            </p>
          </InboxHelperAccordion>
          <InboxHelperAccordion icon={<Event />} text="Drag in schedule to create a scheduled task">
            <p>Click or Drag on available time in your timeline to quickly create a new scheduled task.</p>
          </InboxHelperAccordion>
          <InboxHelperAccordion icon={<ArrowForward2 />} text="Navigate Schedule with a Keyboard">
            <p>
              Use the Arrow keys on your keyboard to navigate days. Combine "Shift" with the Arrow keys to change a
              single day at a time.
            </p>
          </InboxHelperAccordion>
        </InboxHelperAccordion>
      </Box>
    );
  }
}

const ShowProjectHelper = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        padding: '2px 6px 86px 6px',
        gap: '4px',
      }}
    >
      <img
        src="/images/reorder-hint-showproject.svg"
        alt="Reorder a task or schedule it with drag and drop"
        style={{ display: 'block', margin: '0 auto' }}
      />
    </Box>
  );
};

const __ShowProject = (props) => {
  DEVELOPMENT_ENV && console.log('ShowProject - render');
  let { tasks, list, filter, user, searchRegex, showSuggestionForTaskId, autoSuggestMode, dispatch, isPremium } = props;

  const [shareProjectId, setShareProjectId] = useState(null);
  const [expandAllNotes, setExpandAllNotes] = useState(null);
  const [collapseAllNotes, setCollapseAllNotes] = useState(null);

  const preservedVisibleTasksRef = useRef({}); // this will preserve tasks completed in this view from dissapearing
  const preservedVisibleTasks = preservedVisibleTasksRef.current;

  tasks.forEach((task, index) => {
    // if (task.item_order === undefined) task.item_order = index;
    if (!task.completed) preservedVisibleTasks[task.id] = task;
  });

  const visibleTasks = applyVisibilityFilters(tasks, filter, preservedVisibleTasks, user)
    .filter((task) => {
      if (!searchRegex) return true;
      return searchRegex.test(task.title) || (task.note && searchRegex.test(note2text(task.note)));
    })
    .sort(sortByItemOrder);
  // console.log('tasks', tasks)

  const autoScheduleTasks = useMemo(
    () => visibleTasks.filter((t) => !t.completed && !t.eventId && !t.recurringEventIds),
    [visibleTasks]
  );
  const totalVisibleTasksCount = autoScheduleTasks.length;
  const overdueTasksCount = useMemo(() => visibleTasks.filter((task) => isOverdue(task)).length, [visibleTasks]);
  useEffect(() => {
    dispatch(actions.setCurrentViewTasksCounts(overdueTasksCount, totalVisibleTasksCount));
  }, [dispatch, overdueTasksCount, totalVisibleTasksCount]);

  let sharedWith, assignToList;
  if (list.shared && list.shared.members) {
    sharedWith = list.shared.members.map((u) => <MenuItem key={u.email}>{u.displayName || u.email}</MenuItem>);
    assignToList = list.shared.members;
  }

  let shareListIcon = (
    <IconMenu
      size="small"
      iconButtonElement={
        <Tooltip title="Share List" placement="bottom" disableInteractive>
          <IconButton style={{ height: 40, width: 40, padding: '8px' }}>
            <ShareIcon sx={({ palette }) => ({ color: list.shared ? list.color : palette.info.light })} />
          </IconButton>
        </Tooltip>
      }
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      //targetOrigin={{ horizontal: 'left', vertical: 'top' }}
    >
      {sharedWith}
      {list.shared && <Divider />}
      {/* temporary disabled */}
      <MenuItem
        value="new"
        onClick={() => {
          setShareProjectId(list.id);
        }}
      >
        Sharing Settings
      </MenuItem>
    </IconMenu>
  );

  const onExpandAllNotes = () => {
    setExpandAllNotes(true);
    setTimeout(() => setExpandAllNotes(null), 1000);
  };
  const onCollapseAllNotes = () => {
    setCollapseAllNotes(true);
    setTimeout(() => setCollapseAllNotes(null), 1000);
  };

  const saveListAssociatedUserScheduleZoneId = (list, associatedUserScheduleZoneId) => {
    listOps.editList({ ...list, associatedUserScheduleZoneId });
    Analytics.event({
      category: 'List',
      action: 'Set List Associated User Schedule Zone',
    });
  };

  const saveListAssociatedCalendarId = (list, associatedCalendarId) => {
    listOps.editList({ ...list, associatedCalendarId });
    Analytics.event({
      category: 'List',
      action: 'Set List Associated Calendar',
    });
  };

  return (
    <Box
      id="show-project"
      className="tasks-panel-tab-content"
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 48px)', // Toolbar height
      }}
    >
      <Box
        className="project-content tasks-panel-tab-content-container with-task-panel"
        sx={{
          height: '100%',
          overflow: 'hidden auto',
          overflowScrolling: 'touch',
        }}
      >
        <Box className="project-info">
          <Stack
            direction="row"
            className="project-options"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '10px 5px 0 16px',
              gap: '8px',
            }}
          >
            <h1 style={{ flexGrow: 1, color: list.color, fontWeight: 300, fontSize: '1.3em' }}>
              <ListNameWithIcon list={list} />
            </h1>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '0',
                background: theme.palette.faded.background,
                borderRadius: '50px',
                padding: '1px',
              }}
            >
              {list.origin === 'trevor' && shareListIcon}

              <UserScheduleZonesButton
                selectedItemId={list.associatedUserScheduleZoneId}
                onSelectItem={(associatedUserScheduleZoneId) => {
                  if (!isPremium && associatedUserScheduleZoneId) {
                    PremiumModalDock.showUpgradeModule('bind-schedule-zone');
                    return;
                  }
                  console.log('Save associatedUserScheduleZoneId', associatedUserScheduleZoneId);
                  saveListAssociatedUserScheduleZoneId(list, associatedUserScheduleZoneId);
                }}
                placeholder="Scheduling block"
                tooltip="Link List to Scheduling Block"
                rightAction={!isPremium && <Lock />}
                listColor={list.color}
              />
              <EnabledCalendarsButton
                selectedItemId={list.associatedCalendarId}
                onSelectItem={(associatedCalendarId) => {
                  if (!isPremium && associatedCalendarId) {
                    PremiumModalDock.showUpgradeModule('bind-calendar');
                    return;
                  }
                  console.log('Save associatedCalendarId', associatedCalendarId);
                  saveListAssociatedCalendarId(list, associatedCalendarId);
                }}
                placeholder="Calendar"
                tooltip="Override Scheduling Calendar"
                rightAction={!isPremium && <Lock />}
              />
              <Tooltip title="Add/remove list to Scheduling queue">
                <IconButton onClick={() => taskOps.toggleListShedulingQueue(list)} sx={{ padding: '9px' }}>
                  <SmartSchedulingIcon color={list.queue ? list.color : 'inherit'} />
                </IconButton>
              </Tooltip>
              <ListOptions onExpandAllNotes={onExpandAllNotes} onCollapseAllNotes={onCollapseAllNotes} />
            </Box>
          </Stack>
        </Box>

        {autoSuggestMode && !showSuggestionForTaskId && <AutoScheduler tasks={autoScheduleTasks} />}

        <Note
          key={list.id}
          uniqueKey={list.id}
          content={list.note}
          onSave={(content) => sharingOps.saveListNote(list, content)}
          expand={expandAllNotes}
          collapse={collapseAllNotes}
          placeholder="..."
        />
        <SortableList
          handleClass="drag-handle"
          placeholderClass="task task-placecholder task-in-move"
          disableSorting={getExtensionsRegistry()[list?.origin]?.capabilities?.reorder_tasks === false}
          onChange={(newOrder) => {
            console.log('items new order', newOrder);
            let reorderedTasks = newOrder.map((oldIndex, newIndex) => ({
              id: visibleTasks[oldIndex].id,
              item_order: visibleTasks[newIndex].item_order ?? newIndex,
            }));
            taskOps.reorderTasks(reorderedTasks);

            // it's to assure collapsed task won't be reopened after re-render
            if (showSuggestionForTaskId) {
              dispatch(actions.clearShowSuggestionsForTaskId(showSuggestionForTaskId));
            }
          }}
          //onTouchDrag={taskOps.dragTask}
          //onTouchDragStop={() => taskOps.dragTask(false)}
          onDragStart={() => dispatch(actions.changeActiveView(1))}
          items={visibleTasks}
          dragAndDropTaskScheduling
          renderItem={(task, index) => {
            const showSuggestion = showSuggestionForTaskId === task.id;

            return (
              <SortableItemWithTaskProps key={task.id} task={task} index={index}>
                <ScrollIntoViewOnMount activate={showSuggestion}>
                  {/*<DragHandle handleClass="drag-handle"/>*/}
                  <TaskCard
                    {...props}
                    task={task}
                    hintExpand={showSuggestion}
                    onToggleExpand={(isExpand) => {
                      if (!isExpand) {
                        dispatch(actions.clearShowSuggestionsForTaskId(task.id));
                      } else {
                        dispatch(actions.setShowSuggestionsForTaskId(task.id));
                      }
                    }}
                    renderPreview={({ onToggleExpand, onShowTaskOptions, taskOptions }) => {
                      return (
                        // <div className="drag-handle visible-when-dragging">
                        <DraggableWithTaskProps
                          key={task.id}
                          task={task}
                          analiticsHint="AggregatedTasksList"
                          onTouchDrag={taskOps.dragTask}
                          onTouchDragStop={() => taskOps.dragTask(false)}
                        >
                          <TaskTitleWithControls
                            task={task}
                            expandToggle={onToggleExpand}
                            showSuggestion={showSuggestion}
                            assignToList={assignToList}
                            listColor={list.color}
                            showTaskOptions={() => {
                              onShowTaskOptions();
                            }}
                            taskOptions={taskOptions}
                          />
                        </DraggableWithTaskProps>
                      );
                    }}
                    // titleClassName="drag-handle visible-when-dragging"
                    showNoteInPreview
                    expandNote={expandAllNotes}
                    collapseNote={collapseAllNotes}
                  />
                </ScrollIntoViewOnMount>
              </SortableItemWithTaskProps>
            );
          }}
        />
        <ShowProjectHelper />
      </Box>
      <MediaQuery query={desktopScreen}>
        <AddNewTaskPanel {...props} showProject={list} />
      </MediaQuery>
      {shareProjectId && <ShareWithPeopleDialog listId={shareProjectId} onClose={() => setShareProjectId(null)} />}
    </Box>
  );
};

export const ShowProject = connect((state, props) => {
  const { selectedListId, filter } = state.ui;
  const { user } = state.account;
  const list = selectedListId ? state.tasks.listsObj[selectedListId] : null;
  return {
    tasks: getTasksForList(state, selectedListId),
    showSuggestionForTaskId: state.ui.showSuggestionForTaskId,
    autoSuggestMode: state.calendar.autoSuggestMode,
    lists: getEnabledLists(state),
    list,
    filter,
    user,
    searchRegex: searchStringToRegex(state),
    isPremium: user.isPremium,
  };
})(__ShowProject);

const filterColor = 'gray',
  autoScheduleQueue = '__schedule_queue__',
  todayFilterId = '__trev_filter_today__',
  tomorrowFilterId = '__trev_filter_tomorrow__',
  sevenDaysFilterId = '__trev_filter_7days__';

export const __ListsDropdown = ({
  lists,
  selectedListId = null,
  onSelectList,
  placeholder = '#Lists',
  flexGrow = null,
  tooltip = null,
}) => {
  let listColor = filterColor;
  const listItems = lists.map((list, idx) => {
    if (list === null) return <Divider key={'divider' + idx} />;

    if (list.id === selectedListId) {
      listColor = list.color;
    }

    const icon = list.icon ?? integrationIcon(list);
    const color = list.color;

    return (
      <MenuItem key={list.id} value={list.id} style={{ color }}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{list.name}</ListItemText>
      </MenuItem>
    );
  });

  const handleSelect = (e) => {
    onSelectList(e.target.value);
  };

  return (
    <BorderlessSelect
      variant="outlined"
      displayEmpty
      style={{
        color: listColor,
      }}
      sx={{
        height: '48px',
        flexGrow: flexGrow || 1,
        flexBasis: 0,
        minWidth: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '&:hover': { textDecoration: 'underline' },
      }}
      value={selectedListId || ''}
      renderValue={(val) => {
        const content = (val && lists.find((l) => l?.id === val)?.name) || placeholder;
        return tooltip ? (
          <Tooltip title={tooltip}>
            <span>{content}</span>
          </Tooltip>
        ) : (
          content
        );
      }}
      onChange={handleSelect}
      className="add-new-task-panel-lists"
    >
      {listItems}
    </BorderlessSelect>
  );
};
export const EnabledListsDropdown = connect(
  (state, props) => {
    let enabledLists = getEnabledLists(state);
    const customFilters = getFilters(state) || [];
    const specialFilters = [
      {
        id: autoScheduleQueue,
        name: 'Smart Scheduling Queue',
        color: theme.palette.primary.main,
        icon: <SmartSchedulingIcon color={theme.palette.primary.main} />,
      },
      null,
      { id: todayFilterId, name: 'Today', color: filterColor },
      { id: tomorrowFilterId, name: 'Tomorrow', color: filterColor },
      { id: sevenDaysFilterId, name: 'Next 7 days', color: filterColor },
      null,
    ];
    const isTodoistUser = state.account.user && state.account.user.accounts.todoist;
    if (props.showTodoistFilters && isTodoistUser) {
      for (const f of customFilters) {
        specialFilters.push({ ...f, origin: 'todoist' });
      }
      if (customFilters.length > 0) {
        specialFilters.push(null);
      }
    }
    return {
      lists: [...specialFilters, ...enabledLists],
      selectedListId: state.ui.selectedListId,
    };
  },
  (dispatch) => {
    return {
      onSelectList: (listId) => dispatch(actions.selectList(listId)),
    };
  }
)(__ListsDropdown);

class TasksPanel extends React.PureComponent {
  onFocus() {
    Analytics.event({
      category: 'Inbox',
      action: 'Searched in Inbox',
    });
  }

  toggleSettings = () => {
    const { tab, onShowListsSettings, onChangeTab, selectedListId, onSelectListNone } = this.props;
    if (tab !== 'project') {
      onShowListsSettings();
      onChangeTab('project');
      if (selectedListId) {
        onSelectListNone();
      }
    } else {
      onChangeTab('inbox');
    }
  };

  render() {
    DEVELOPMENT_ENV && console.log('TasksPanel - render');
    const {
      tab,
      onChangeTab,
      selectedListId,
      lists,
      filters,
      onSelectListNone,
      searchString,
      searchMode,
      // searchRegex,
      onSearchOn,
      onSearchOff,
      onSearchStringChange,
      autoSuggestMode,
      onToggleAutoSuggest,
      pendingInvitations,
    } = this.props;

    let content, showUserFilter;

    if (tab === 'project') {
      content = <ListsSettings lists={this.props.lists} tasks={this.props.tasks} goBack={() => onChangeTab('inbox')} />;
    } else if (tab === 'inbox') {
      if (selectedListId) {
        const selectedList = lists.find((it) => it.id === selectedListId);
        const selectedFilter = filters.find((f) => f.id === selectedListId);
        if (selectedList) {
          content = <ShowProject />;
          if (selectedList.shared && selectedList.shared.members && selectedList.shared.members.length > 1)
            showUserFilter = true;
        } else if (selectedFilter) {
          content = <AggregatedFilteredTasksList filter={selectedFilter} />;
        } else {
          if (selectedListId === todayFilterId) {
            content = <AggregatedTasksListWithMaxDueDate due={moment().endOf('day')} {...this.props} />;
          } else if (selectedListId === tomorrowFilterId) {
            content = (
              <AggregatedTasksListWithMaxDueDate
                due={moment().add(1, 'days').endOf('day')}
                after={moment().endOf('day')}
                // due={moment().add(1, 'days').format('YYYY-MM-DD')}
                // after={moment().format('YYYY-MM-DD')}
                {...this.props}
              />
            );
          } else if (selectedListId === sevenDaysFilterId) {
            content = <AggregatedTasksListWithMaxDueDate due={moment().endOf('day').add(6, 'days')} {...this.props} />;
          } else if (selectedListId === autoScheduleQueue) {
            content = <AggregatedTasksListWithScheduleQueue />;
            Analytics.event({ category: 'Inbox', action: 'Opened Scheduling Queue' });
          } else {
            content = '';
            onSelectListNone();
          }
        }
      } else {
        showUserFilter = lists.some((list) => list.shared?.members?.length);
        content = <Inbox toggleSettings={this.toggleSettings} />;
      }
    }

    var dynBtnIcon = <ArrowBackward color="info" />;
    if (!selectedListId && tab === 'inbox') {
      dynBtnIcon = <InboxIcon color="primary" />;
    }

    var tabs = (
      <>
        <Tooltip title="All tasks" disableInteractive>
          <IconButton
            style={{ marginLeft: 0 }}
            edge="start"
            onClick={(e) => {
              onSelectListNone();
              onChangeTab('inbox');
            }}
          >
            {dynBtnIcon}
          </IconButton>
        </Tooltip>
        <EnabledListsDropdown showTodoistFilters />
        <Tooltip title="Search tasks" disableInteractive>
          <IconButton sx={{ margin: '0 4px 0 0' }} disabled={tab === 'project'} onClick={onSearchOn}>
            <ActionSearch />
          </IconButton>
        </Tooltip>
        <InboxFilters showUser={showUserFilter} />
        {/* <Tooltip title="Show/hide scheduling suggestions" disableInteractive>
          <IconButton sx={{ margin: '0 4px 0 0' }} onClick={onToggleAutoSuggest}>
            <TransparentBadge badgeContent={42} textColor={autoSuggestMode ? theme.palette.primary.main : 'inherit'}>
              <SchedulingSuggestionsIcon color={autoSuggestMode ? theme.palette.primary.main : 'inherit'} />
            </TransparentBadge>
          </IconButton>
        </Tooltip> */}
        <Tooltip title="Add & edit task #lists" disableInteractive>
          <Badge badgeContent={pendingInvitations.length} color="primary">
            <IconButton
              style={{ marginRight: 0 }}
              edge="end"
              onClick={() => this.toggleSettings()}
              className="task-panel-tabs-settings" // hightlight()
            >
              <PlaylistAdd color={tab === 'project' ? 'primary' : undefined} />
            </IconButton>
          </Badge>
        </Tooltip>
      </>
    );

    if (searchMode) {
      tabs = (
        <>
          <IconButton edge="start" onClick={onSearchOff} style={{ marginTop: '4px', marginBottom: '4px' }}>
            <ActionSearch color="primary" />
          </IconButton>
          <TextField
            className="task-panel-tabs-search-field"
            value={searchString || ''}
            placeholder="Search tasks and #lists..."
            onChange={(e) => onSearchStringChange(e.target.value)}
            onFocus={this.onFocus}
            autoFocus
            variant="standard"
            sx={{
              flexGrow: 1,
              height: '32px',
              margin: '8px 0',
              flexBasis: 0,
              minWidth: 0,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          />
          <InboxFilters showUser={showUserFilter} />
          <IconButton onClick={onSearchOff} edge="end">
            <NavigationClose />
          </IconButton>
        </>
      );
    }

    DEVELOPMENT_ENV && console.log('TasksPanel - render - end');
    return (
      <Paper
        id="tasks-panel"
        elevation={0}
        style={{ position: 'relative', height: 'inherit', width: 'inherit', borderRadius: 'inherit' }}
        sx={{
          backgroundColor: (theme) => theme.inbox.backgroundColor,
          boxShadow: (theme) => theme.shadow.box,
        }}
      >
        <PostponePlaceholder />
        <Box
          id="tasks-panel-tabs"
          elevation={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignItems: 'center',
            alignContent: 'center',
            width: '100%',
            boxShadow: 'none',
            borderRadius: 'inherit',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Toolbar
            id="tasks-panel-tabs-toolbar"
            variant="dense"
            sx={{
              width: '100%',
              maxWidth: (theme) => 'calc(100% - ' + theme.mainBox.paddingInt * 2 + 'px)',
              zIndex: 2,
              boxShadow: (theme) => theme.shadow.soft,
              borderRadius: 'inherit',
              paddingLeft: (theme) => theme.mainBox.padding + ' !important',
              paddingRight: (theme) => theme.mainBox.padding + ' !important',
              [`& button`]: {
                margin: '0',
                height: '40px',
                width: '40px',
              },
            }}
          >
            {tabs}
          </Toolbar>
        </Box>
        {content}
      </Paper>
    );
  }
}

export default connect(
  (state, ownProps) => {
    DEVELOPMENT_ENV && console.log('TasksPanelWired ownProps', ownProps, 'state:', state);
    return {
      ...ownProps,
      tasks: getVisibleTasksWithLists(state),
      lists: getAllLists(state),
      filters: getFilters(state),
      defaultListId: state.tasks.defaultListId,
      selectedListId: state.ui.selectedListId,
      autoSuggestMode: state.calendar.autoSuggestMode,
      tab: state.ui.tab,
      pendingInvitations: getPendingInvitations(state),

      searchMode: state.ui.searchMode,
      searchString: state.ui.searchString,
    };
  },
  (dispatch, ownProps) => ({
    onChangeTab: (tab) => {
      Analytics.event({
        category: 'Inbox',
        action: 'Switched to ' + tab,
      });
      dispatch(actions.changeTasksTab(tab));
    },
    onSelectListNone: () => dispatch(actions.selectListNone()),
    onShowListsSettings: () => {
      dispatch(actions.showListsSettings());
    },
    onShowInbox: () => {
      dispatch(actions.showInbox());
    },
    onSearchOn: () => dispatch(actions.searchModeOn()),
    onSearchOff: () => dispatch(actions.searchModeOff()),
    onSearchStringChange: (str) => dispatch(actions.searchStringChange(str)),
    onToggleAutoSuggest: () => dispatch(actions.setAutoSuggestMode()),
  })
)(TasksPanel);
