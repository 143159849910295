// import 'babel-polyfill'
import moment from 'moment';
import { put, take, takeEvery, call, select, fork, cancel, actionChannel, delay } from 'redux-saga/effects';
import { eventChannel, buffers } from 'redux-saga';
import { ajax_get, ajax_post, ajax_put } from '../api/util';
import {
  ADD_EVENT_REQUEST,
  addEventSuccess,
  addEventFailed,
  addEventRequest,
  putEventToUI,
  removeEventFromUI,
  UPDATE_EVENT_REQUEST,
  updateEventFailed,
  updateEventSuccess,
  ADD_EVENT_WITH_TASK_REQUEST,
  putEventWithTaskToUI,
  removeEventWithTaskFromUI,
  addEventWithTaskFailed,
  addEventWithTaskSuccess,
  RENAME_EVENT_REQUEST,
  renameEventFailed,
  renameEventSuccess,
  START_CALENDAR_WATCHER,
  CHANGE_CALENDAR_DATE_RANGE,
  CHANGE_CALENDAR_ENABLED,
  initialCalendarLoad,
  externalCalendarsUpdate,
  TURN_EVENT_INTO_TASK,
  turnEventIntoTaskFailed,
  turnEventIntoTaskSuccess,
  changeCalendarDateRange,
} from '../actions';
import { highestItemOrderInList, ObjectValues } from 'common/utils';
import { createAssociation, getCurrentUserUid, saveToUserProfile } from '../common/firebase';
import {
  getDatabase,
  ref,
  off,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  onValue,
  query,
  startAt,
  endAt,
  orderByChild,
} from 'firebase/database';
import { getUid } from '../operations';
import isDeepEqual from 'lodash.isequal';
import { CalendarEvent } from 'shared';
import { getAndSaveNExtRecurringInstanceIfNeeded } from 'operations/event';
import { getRecurringEventIdsToTaskIds } from 'reducers/tasks';
import { ReduxState } from 'reducers';

const db = getDatabase();

let inProgressEvents = {};

export function* addEvent(action: ReturnType<typeof addEventRequest>) {
  const {
    suggestId,
    title,
    // calendarId,
    taskId,
    taskCompleted,
    beginDate,
    endDate,
    allDay,
    description,
  } = action;
  const accountUser = yield select((state) => state.account.user);
  // const { defaultReminderMinutesBeforeStart } = accountUser.accounts.outlook;

  const tasks = yield select((state) => state.tasks);
  const taskList = tasks.listsObj[tasks.tasksObj[taskId]?.listId || tasks.defaultListId];
  const calendarId = taskList?.associatedCalendarId || accountUser.defaultCalendarId;

  const event = {
    suggestId,
    title,
    calendarId,
    taskId,
    taskCompleted,
    beginDate,
    endDate,
    allDay,
    description,
    // suggestReminderMinutesBeforeStart: defaultReminderMinutesBeforeStart,
  };
  yield put(putEventToUI({ ...action, id: suggestId, calendarId, inProgress: true }));

  inProgressEvents[suggestId] = action;
  try {
    const resp = yield call(ajax_post, '/api/event', { event });
    const { id } = resp.event;
    console.log('addEvent', resp);

    yield put(addEventSuccess({ ...action, id }, suggestId !== id ? suggestId : null, true));
    if (inProgressEvents[suggestId] === action) {
      delete inProgressEvents[suggestId];
      // yield put(putEventToUI({ ...action, id, inProgress: false }));
    }
  } catch (err) {
    yield put(addEventFailed(action, err));
    yield put(removeEventFromUI(suggestId));
    delete inProgressEvents[suggestId];
  }
}

export function* addEventWithTask(action) {
  const stateTasks = yield select((state) => state.tasks);
  const allTasks = ObjectValues(stateTasks.tasksObj);
  const accountUser = yield select((state) => state.account.user);
  // const { defaultReminderMinutesBeforeStart } = accountUser.accounts.outlook;

  const listId = action.listId || stateTasks.defaultListId;

  const { title, beginDate, endDate, allDay, suggestId, tempId, description } = action;

  const taskList = stateTasks.listsObj[listId];
  // it's also done in server but without calendarId here that event appears very slowly in the calendar
  const calendarId = taskList?.associatedCalendarId || accountUser.defaultCalendarId;

  const event = {
    title,
    calendarId,
    beginDate,
    endDate,
    allDay,
    id: suggestId,
    suggestId,
    description,
    // suggestReminderMinutesBeforeStart: defaultReminderMinutesBeforeStart,
  };
  const item_order = 1 + highestItemOrderInList(allTasks, listId);
  const task = { title, listId, tempId, item_order };

  inProgressEvents[suggestId] = action;
  const eventTaskInProgressAction = putEventWithTaskToUI({
    title,
    calendarId,
    listId,
    item_order,
    eventId: suggestId,
    beginDate,
    endDate,
    allDay,
    tempId,
    taskId: tempId,
    inProgress: true,
  });
  yield put(eventTaskInProgressAction);

  try {
    // const taskResp = yield call(ajax_post, '/api/task', {
    //   task: {
    //     ...task,
    //     item_order,
    //     // eventId,
    //     // eventBeginDate: beginDate,
    //     // eventEndDate: endDate,
    //   },
    // });
    // const taskId = taskResp.id;
    // const eventResp = yield call(ajax_post, '/api/event', { event: { ...event, taskId } });
    // const eventId = eventResp.event.id;
    // yield call(createAssociation, userId, taskId, eventId, beginDate, endDate);

    const { taskId, eventId, calendarId } = yield call(ajax_post, '/api/task-event', { task, event });

    yield put(
      addEventWithTaskSuccess(
        { eventId, calendarId, tempId, title, beginDate, endDate },
        taskId,
        eventId !== suggestId ? suggestId : null,
        true
      )
    );
    if (inProgressEvents[suggestId] === action) {
      // yield put(putEventWithTaskToUI({ eventId, taskId, inProgress: false }));
      delete inProgressEvents[eventId];
    }
  } catch (err) {
    yield put(addEventWithTaskFailed(action, err));
    yield put(removeEventWithTaskFromUI(suggestId, tempId));
    delete inProgressEvents[suggestId];
    // TODO attempt to delete event if created
  }
}

export function* turnEventIntoTask(action) {
  const { event } = action;
  const { id, title, recurringEventId, beginDate, endDate } = event;

  const stateTasks = yield select((state) => state.tasks),
    allTasks = ObjectValues(stateTasks.tasksObj);

  const listId = stateTasks.defaultListId;
  const item_order = 1 + highestItemOrderInList(allTasks, listId);

  try {
    const taskResp = yield call(ajax_post, '/api/task', {
      task: {
        title,
        listId,
        item_order,
      },
    });
    const taskId = taskResp.id;

    yield call(createAssociation, getCurrentUserUid(), taskId, id, beginDate, endDate, recurringEventId);

    if (recurringEventId) {
      yield call(getAndSaveNExtRecurringInstanceIfNeeded, event, taskResp);
    }

    yield put(turnEventIntoTaskSuccess({ ...action, listId }, taskId));
  } catch (err) {
    yield put(turnEventIntoTaskFailed({ ...action, listId }, err));
  }
}

export function* updateEvent(action) {
  let {
    id,
    extraLongId,
    title,
    calendarId,
    taskId,
    recurringEventMatchingTaskId,
    beginDate,
    endDate,
    allDay,
    reminders,
  } = action;
  let event: Partial<CalendarEvent> = {
    id,
    calendarId,
  };
  if (extraLongId) event.extraLongId = extraLongId;
  if (title) event.title = title;
  if (taskId) event.taskId = taskId;
  if (beginDate) event = { ...event, beginDate, endDate, allDay };
  if (reminders) event = { ...event, reminders };

  const originalEvent = yield select((state) => state.calendar.eventsObj[id]);

  if (originalEvent && originalEvent.recurringEventId && !recurringEventMatchingTaskId) {
    const eventIdsToTaskIds = yield select(getRecurringEventIdsToTaskIds);
    recurringEventMatchingTaskId = eventIdsToTaskIds[originalEvent.recurringEventId];
    console.log('Discovered recurringEventMatchingTaskId', recurringEventMatchingTaskId);
  }

  yield put(putEventToUI({ ...action, inProgress: true }));
  inProgressEvents[id] = action;
  try {
    yield call(ajax_put, '/api/event', { event, recurringEventMatchingTaskId });
    yield put(updateEventSuccess(action));
  } catch (err) {
    yield put(updateEventFailed(action, err));
    if (originalEvent && inProgressEvents[id] === action) yield put(putEventToUI(originalEvent));
  } finally {
    if (inProgressEvents[id] === action) {
      delete inProgressEvents[id];
      yield put(putEventToUI({ id, inProgress: false }));
    }
  }
}

export function* renameEvent(action) {
  const { id, title, calendarId, taskId } = action;
  let event = { id, title, calendarId, taskId };

  const originalEvent: CalendarEvent = yield select((state) => state.calendar.eventsObj[id]);
  if (originalEvent.recurringEventId) {
    const eventIdsToTaskIds = yield select(getRecurringEventIdsToTaskIds);
    (event as any).recurringEventMatchingTaskId = eventIdsToTaskIds[originalEvent.recurringEventId] || null;
  }

  yield put(putEventToUI({ ...action, inProgress: true }));
  inProgressEvents[id] = action;
  try {
    yield call(ajax_put, '/api/event/rename', { event });
    yield put(renameEventSuccess(action, taskId));
  } catch (err) {
    yield put(renameEventFailed(action, err));
    if (originalEvent && inProgressEvents[id] === action) yield put(putEventToUI(originalEvent));
  } finally {
    if (inProgressEvents[id] === action) {
      delete inProgressEvents[id];
      yield put(putEventToUI({ id, inProgress: false }));
    }
  }
}

const goThroughItemsAndStoreById = (args) => {
  for (let key in args.itemsObject) {
    let item = args.itemsObject[key];
    if (args.forEach) item = args.forEach(item);
    args.storeInObject[item.id] = item;
  }
};

const normaliseEvent = (event) => {
  if (event.allDay && event.beginDate === event.endDate) {
    event.endDate = moment(event.endDate).add(1, 'day').format('YYYY-MM-DD');
  }
};

function* changeCalendarEnabled(action) {
  const { calendarId, enabled } = action;

  const disabledCalendarsObj = yield select((state) => state.calendar.disabledCalendarsObj);

  const disabledCalendars = Object.keys(disabledCalendarsObj).filter((id) => id !== calendarId);
  if (!enabled) {
    disabledCalendars.push(calendarId);
  }

  yield call(saveToUserProfile, getUid(), { disabledCalendars });
}

const isChanged = (stateCalendar, calendarsObj, eventsObj) => {
  for (const key in calendarsObj) {
    if (!isDeepEqual(stateCalendar.calendarsObj[key], calendarsObj[key])) {
      console.log('Calendars not equal', stateCalendar.calendarsObj[key], calendarsObj[key]);
      return true;
    }
  }
  for (const key in eventsObj) {
    const { color, enabled, floating, ...stripedStateEvent } = stateCalendar.eventsObj[key] || {};
    if (!isDeepEqual(stripedStateEvent, eventsObj[key])) {
      console.log('Events not equal', stateCalendar.eventsObj[key], eventsObj[key]);
      return true;
    }
  }
  console.log('All update calendar is not changed');
  return false;
};

let initialCalendarsLoadDone = false,
  initialEventsLoadDone = false,
  firstCalendarLoad = true,
  calendarsObj = {},
  eventsObj = {};

function* calendarWatcher(uid, startDate, endDate, disabledCalendarsObj, disabledCalendarsNotSelected) {
  console.log('calendarWatcher', uid, startDate, endDate);
  let channel = eventChannel((emit) => {
    let calendarsRef = ref(db, 'calendars/' + uid);
    let saveCalendarAndTriggerUpdate = (snapshot) => {
      if (!initialCalendarsLoadDone) return; // ignore before initial load done
      let val = snapshot.val();
      calendarsObj[val.id] = val;
      emit({});
    };
    onChildAdded(calendarsRef, saveCalendarAndTriggerUpdate);
    onChildChanged(calendarsRef, saveCalendarAndTriggerUpdate);
    onChildRemoved(calendarsRef, (snapshot) => {
      calendarsObj[snapshot.val().id] = {
        ...snapshot.val(),
        removed: true,
      };
      emit({});
    });
    onValue(
      calendarsRef,
      (snapshot) => {
        console.log('calendars initial load done');
        let calsObj = snapshot.val() || {};
        goThroughItemsAndStoreById({ itemsObject: calsObj, storeInObject: calendarsObj });
        initialCalendarsLoadDone = true;

        // emit({})
      },
      { onlyOnce: true }
    );

    let start = moment(startDate),
      end = moment(endDate);
    let startAtStr = start.format('YYYY-MM-DD'),
      endAtStr = end.endOf('day').toDate().toISOString();

    let saveEventAndTriggerUpdate = (snapshot) => {
      if (!initialEventsLoadDone) return; // ignore until initial load done
      let val = snapshot.val();

      normaliseEvent(val);

      eventsObj[val.id] = {
        ...val,
        removed: val.removed || false,
      };

      emit({});
    };
    console.log('Attach fb listener for ', startAtStr, endAtStr);
    let eventsRef = query(
      ref(db, 'calendar_events/' + uid),
      orderByChild('beginDate'),
      startAt(startAtStr),
      endAt(endAtStr)
    );
    onChildAdded(eventsRef, saveEventAndTriggerUpdate);
    onChildChanged(eventsRef, saveEventAndTriggerUpdate);
    onChildRemoved(eventsRef, (snapshot) => {
      console.log('firebase child_removed', snapshot.val());
      const val = snapshot.val();
      // const existingEvent = this.eventsObj[val.id]
      // if(existingEvent && (moment(existingEvent.beginDate).isBefore(startAt) || moment(existingEvent.beginDate).isSameOrAfter(endAt))) {
      //     console.log('Ignore firebase event remove for', val.id, 'move to another month is detected')
      //     return
      // }
      eventsObj[val.id] = {
        ...val,
        removed: true,
      };
      emit({});
    });
    onValue(
      eventsRef,
      (snapshot) => {
        console.log('events initial load done', startAtStr, endAtStr);
        let __eventsObj = snapshot.val() || {};
        for (let key in __eventsObj) {
          let event = __eventsObj[key];
          normaliseEvent(event);
          eventsObj[event.id] = event;
        }
        initialEventsLoadDone = true;
        emit({});
      },
      { onlyOnce: true }
    );

    return () => {
      off(calendarsRef);
      off(eventsRef);
    };
  }, buffers.dropping(1));

  while (true) {
    yield take(channel);
    console.log('new event from calendars watcher');
    yield delay(300);

    if (firstCalendarLoad) {
      firstCalendarLoad = false;

      // a bit ugly but simplest way to disable todoist calendar by default
      if (disabledCalendarsNotSelected) {
        for (let calId in calendarsObj) {
          const cal = calendarsObj[calId];
          if (cal.title === 'Todoist') {
            disabledCalendarsObj[cal.id] = true;
            break;
          }
        }
      }

      yield put(initialCalendarLoad(calendarsObj, eventsObj, disabledCalendarsObj));
    } else {
      // if(empty(calendarsObj) && empty(eventsObj)) continue
      const stateCalendar = yield select((state) => state.calendar);

      if (isChanged(stateCalendar, calendarsObj, eventsObj)) {
        yield put(externalCalendarsUpdate(calendarsObj, eventsObj));
      }
    }

    calendarsObj = {};
    eventsObj = {};
  }
}

const format = (date) => moment(date).format('YYYY-MM-DD');

let watcher;
function* startWatcher(viewDateChannel) {
  if (watcher !== undefined) return;
  watcher = null;

  let user = yield select((state) => state.account.user);
  const disabledCalendarsNotSelected = !user.disabledCalendars;
  const disabledCalendarsObj = (user.disabledCalendars || []).reduce((acc, id) => {
    acc[id] = true;
    return acc;
  }, {});

  while (true) {
    let action = yield take(viewDateChannel);
    console.log('calendar startWatcher', action);
    let start = moment(action.start),
      end = moment(action.end);
    let diffDays = end.diff(start, 'days');
    if (diffDays > 42) {
      // not sure if needed
      process.env.NODE_ENV === 'development' && console.log('Date range is too wide -', diffDays, 'days. Ignore');
      continue;
    }
    // sync events for same period before and after to make sure events are loaded when users goes back and forth
    if (diffDays <= 7) {
      // don't extend too much for wide ranges - like month view
      start.subtract(diffDays, 'days');
      end.add(diffDays, 'days');
    }

    if (watcher) {
      yield cancel(watcher);
    }

    watcher = yield fork(calendarWatcher, user.uid, start, end, disabledCalendarsObj, disabledCalendarsNotSelected);

    // yield call(ajax, `/api/refresh_events?fromDate=${encodeURIComponent(start)}&toDate=${encodeURIComponent(end)}`, 'GET')
    try {
      const { errors } = yield call(
        ajax_get,
        `/api/refresh_events?fromDate=${encodeURIComponent(format(start))}&toDate=${encodeURIComponent(format(end))}`
      );
      if (errors?.length) {
        console.log('Error refreshing events', errors);
        yield put({ type: 'REFRESH_EVENTS_ERRORS', message: errors.join(', ') });
      }
    } catch (e) {
      console.log('Error refreshing events', e);
      yield put({ type: 'REFRESH_EVENTS_ERRORS', message: e.message });
    }
  }
}

function* periodicRefresher(refreshPeriodMinutes = 60, plusSeconds = 15) {
  console.log('Start periodicRefresher', refreshPeriodMinutes, 'minutes plus', plusSeconds, 'seconds');
  do {
    yield delay(refreshPeriodMinutes * 60 * 1000 + plusSeconds * 1000);
    const viewDatesRange = yield select((state: ReduxState) => state.ui.viewDatesRange);
    console.log('periodicRefresher', viewDatesRange);
    if (viewDatesRange.start) {
      yield put(changeCalendarDateRange(viewDatesRange.start, viewDatesRange.end));
    }
  } while (true);
}

// single entry point to start all Sagas at once
export default function* calendarSaga() {
  let viewDateChannel = yield actionChannel(CHANGE_CALENDAR_DATE_RANGE);
  yield takeEvery(START_CALENDAR_WATCHER, startWatcher, viewDateChannel);
  yield takeEvery(ADD_EVENT_REQUEST, addEvent);
  yield takeEvery(UPDATE_EVENT_REQUEST, updateEvent);
  yield takeEvery(ADD_EVENT_WITH_TASK_REQUEST, addEventWithTask);
  yield takeEvery(TURN_EVENT_INTO_TASK, turnEventIntoTask);
  yield takeEvery(RENAME_EVENT_REQUEST, renameEvent);
  yield takeEvery(CHANGE_CALENDAR_ENABLED, changeCalendarEnabled);

  yield fork(periodicRefresher);
}
