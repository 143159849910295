import React from 'react';
import { Box, Button, Tooltip, SvgIcon } from '@mui/material';
import {
  Event,
  Repeat,
  EventRepeat,
  SportsScore,
  History,
  ExpandCircleDown,
  HighlightOff,
  UnfoldLess,
} from '@mui/icons-material';
import { isOverdue, formatDueScheduledDate } from '.';
import { inSchedulingQueue } from 'shared';
import { theme } from '../../theme';
import moment from 'moment';
import { SmartSchedulingIcon, InSchedulingQueueIcon } from '../../components/CustomIcons';
import { useSelector } from 'react-redux';

export const TaskScheduleWidget = ({ task, listColor, onClick, showSuggestion = false }) => {
  let content,
    color = theme.palette.faded.main,
    tooltip,
    icon;
  let taskOverdue = isOverdue(task) || false;
  let pastDueDate = moment(task.dueDate).isBefore(moment()) || false; // Today is considered past due
  const listsObj = useSelector((state) => state.tasks.listsObj);
  const prefs = useSelector((state) => state.account.user.prefs);
  const ctx = {
    momenttz: moment,
    prefs,
  };
  let inQueue = inSchedulingQueue(ctx, task, listsObj) || false;

  // States Legend
  // List color indicates that a task is either scheduled or is in the scheduling queue
  // Icons indicate whether a task is recurring or in scheduling queue

  if ((task.eventId || task.nextInstEventId) && task.eventBeginDate) {
    // Scheduled - colored date (hidden by default)
    // Overdue - red date with icon
    content = formatDueScheduledDate(task.eventBeginDate);
    color = listColor;
    tooltip = 'Scheduled';
    if (taskOverdue) {
      color = theme.palette.danger.main;
      tooltip = 'Overdue';
      icon = <History color="inherit" />;
    }

    // Scheduled Recurrence - colored repeating event icon with date and tooltip
    // Overdue Recurrence - red repeating event icon with date and tooltip
    if (task.recurringEventIds) {
      icon = <EventRepeat color="inherit" />;
      tooltip = `Recurrence Scheduled for ${content}`;
    }
  } else if (task.dueDate) {
    // Due Date - gray date
    // Past Due Date - red date with icon
    content = formatDueScheduledDate(task.dueDate);
    color = theme.palette.faded.main;
    tooltip = 'Due Date';
    if (pastDueDate) {
      color = theme.palette.danger.main;
      tooltip = 'Past ' + tooltip;
      icon = <SportsScore color="inherit" />;
    }

    // Flexible Recurrence - orange (inherit) repeat icon with date and recurrence rule in tooltip
    if (task.recurrence) {
      icon = <Repeat color="inherit" />;
      tooltip = `Recurring ${task.recurrence}`;
      // Flexible Recurrence in Queue - colored trevor icon with date and recurrence rule in tooltip
      // TODO: Handle overdue flexible recurrences if needed. They seem to be pastDueDate true by default, as they appear on the day of the recurring due date.
      if (inQueue) {
        color = listColor;
        tooltip = tooltip + ' | In Scheduling Queue';
      }
    }

    // Due Date in Queue - colored trevor icon with date
    // Past Due in Queue - orange trevor icon with date
    if (inQueue && !task.recurrence) {
      icon = <SmartSchedulingIcon color="inherit" size="16px" />;
      color = pastDueDate ? theme.palette.warning.main : listColor;
      tooltip = tooltip + ' | In Scheduling Queue';
    }
  } else {
    // Default - gray calendar icon
    // Default in Queue - colored trevor icon
    color = inQueue ? listColor : theme.palette.faded.main;
    content = inQueue ? <SmartSchedulingIcon color={color} /> : <Event color="inherit" />;
    if (inQueue) {
      tooltip = 'In Scheduling Queue';
    } else {
      tooltip = 'Show Scheduling Options';
    }
  }

  if (showSuggestion && !taskOverdue && !pastDueDate) {
    content = <UnfoldLess />;
    tooltip = 'Hide Scheduling Options';
  }

  return (
    <Box sx={{ maxWidth: '150px' }}>
      <Tooltip title={tooltip} placement="top" disableInteractive>
        <Button
          className="task-schedule-widget"
          onClick={onClick}
          sx={{
            fontSize: '13px',
            minWidth: '40px',
            color, // does not accept mui color values like 'danger'. Button color prop does not accept rgb values.
            whiteSpace: 'nowrap',
            textTransform: 'none',
            '.MuiButton-startIcon': {
              marginRight: '3px',
              svg: { fontSize: '18px' },
            },
          }}
          startIcon={icon ? icon : ''}
        >
          {content}
        </Button>
      </Tooltip>
    </Box>
  );
};
