import { createSelector } from 'reselect';
import {
  INITIAL_TASKS_LOAD,
  EXTERNAL_TASKS_UPDATE,
  PUT_EVENT_TO_UI,
  ADD_EVENT_FAILED,
  ADD_EVENT_SUCCESS,
  PUT_TASK_TO_UI,
  REMOVE_TASK_FROM_UI,
  ADD_TASK_FAILED,
  ADD_TASK_SUCCESS,
  PUT_EVENT_WITH_TASK_TO_UI,
  REMOVE_EVENT_WITH_TASK_FROM_UI,
  ADD_EVENT_WITH_TASK_FAILED,
  ADD_EVENT_WITH_TASK_SUCCESS,
  CHANGE_LIST_ENABLED,
  SET_TASK_DUE_DATE,
  SET_TASK_RECURRENCE,
} from '../actions';
import { colorIdToRGB, ObjectValues, isOverdue, defaultColor } from 'common/utils';
import moment from 'moment';
import { ReduxState } from 'reducers';
import { Filter, Invitation, List, Task, User, inSchedulingQueue } from 'shared';

const findDefaultList = (listsObj) => {
  const lists = ObjectValues(listsObj);
  return lists.find((list) => list.default) || lists.find((list) => list.enabled);
};

const defaultState = {
  listsObj: {},
  tasksObj: {},
  removedTasksObj: {},
  filtersObj: {},
  defaultListId: null,
  inProgressObj: {},
  // predictionsByTaskId: {},
};

export const tasks = (state = defaultState, action) => {
  switch (action.type) {
    case INITIAL_TASKS_LOAD: {
      const { listsObj, tasksObj, filtersObj } = action;
      // console.log('init', listsObj, tasksObj,  action)
      let filteredListsObj = {};
      for (let listId in listsObj) {
        let list = { ...listsObj[listId] };
        if (list.removed) {
          continue;
        }
        if (list.colorId) list.color = colorIdToRGB(list.colorId);
        else if (!list.color) list.color = defaultColor;
        list.enabled = !!list.enabled;
        filteredListsObj[list.id] = list;
      }
      let defaultList = findDefaultList(filteredListsObj);

      let filteredTasksObj = {};
      for (let id in tasksObj) {
        let task = { ...tasksObj[id] };
        let list = filteredListsObj[task.listId];
        if (!list || task.removed) continue;
        filteredTasksObj[task.id] = task;
      }
      return {
        ...state,
        listsObj: filteredListsObj,
        tasksObj: filteredTasksObj,
        filtersObj,
        defaultListId: defaultList ? defaultList.id : null,
      };
    }
    case EXTERNAL_TASKS_UPDATE: {
      const { listsObj, tasksObj, filtersObj } = action;
      let filteredListsObj = { ...state.listsObj };
      let filteredTasksObj = { ...state.tasksObj };
      let removedTasksObj = {};

      let updated = false;

      if (listsObj) {
        for (let listId in listsObj) {
          updated = true;
          // allows update with partial data
          let list = { ...filteredListsObj[listId], ...listsObj[listId] };
          if (list.removed) {
            delete filteredListsObj[list.id];
            continue;
          }
          if (list.colorId) list.color = colorIdToRGB(list.colorId);
          else if (!list.color) list.color = defaultColor;
          list.enabled = !!list.enabled;
          filteredListsObj[list.id] = list;
        }
      }

      if (tasksObj) {
        for (let id in tasksObj) {
          if (state.inProgressObj[id] || state.inProgressObj[tasksObj[id].tempId]) {
            continue;
          }
          updated = true;
          let task = tasksObj[id];
          let list = filteredListsObj[task.listId];
          if (!list || task.removed) {
            removedTasksObj[id] = task;
            delete filteredTasksObj[task.id];
            continue;
          }

          // It seems the below is not needed anymore
          // let oldTaskStructure = filteredTasksObj[id];
          // let { eventId, eventBeginDate, eventEndDate, recurringEventIds } = task;
          // task = {
          //   ...oldTaskStructure,
          //   assignedTo: null,
          //   ...task,
          // }; // allows update with partial data
          // if (!eventId) delete task.eventId;
          // if (!eventBeginDate) delete task.eventBeginDate;
          // if (!eventEndDate) delete task.eventEndDate;
          // if (!recurringEventIds) delete task.recurringEventIds;

          filteredTasksObj[id] = task;
        }
      }

      if (!updated) return state;

      let defaultList = findDefaultList(filteredListsObj);
      return {
        ...state,
        listsObj: filteredListsObj,
        tasksObj: filteredTasksObj,
        removedTasksObj,
        filtersObj,
        defaultListId: defaultList ? defaultList.id : null,
      };
    }

    case CHANGE_LIST_ENABLED: {
      const { listId, enabled } = action;

      let listsObj = {
        ...state.listsObj,
        [listId]: {
          ...state.listsObj[listId],
          enabled,
        },
      };

      return {
        ...state,
        listsObj,
      };
    }

    case PUT_EVENT_WITH_TASK_TO_UI:
    case PUT_TASK_TO_UI: {
      let {
        id,
        tempId,
        taskId,
        title,
        taskCompleted,
        completed,
        listId,
        item_order,
        eventId,
        eventBeginDate,
        eventEndDate,
        beginDate,
        endDate,
        inProgress,
        recurringEventIds,
      } = action;
      id = id || taskId || tempId;
      eventBeginDate = eventBeginDate || beginDate;
      eventEndDate = eventEndDate || endDate;
      completed = completed !== undefined ? completed : taskCompleted;
      let existingTask = state.tasksObj[id] || {};
      let task = {
        ...existingTask,
        id,
      };
      if (eventId && eventBeginDate) {
        task = { ...task, eventId, eventBeginDate, eventEndDate };
      }
      if (title) task.title = title;
      if (tempId) task.tempId = tempId;
      if (listId) task.listId = listId;
      if (item_order) task.item_order = item_order;
      if (recurringEventIds) task.recurringEventIds = recurringEventIds;
      if (completed !== undefined) task.completed = completed;

      let inProgressObj = { ...state.inProgressObj };
      if (inProgress) {
        inProgressObj[id] = true;
      } else {
        delete inProgressObj[id];
      }
      return {
        ...state,
        tasksObj: {
          ...state.tasksObj,
          [id]: task,
        },
        inProgressObj,
      };
    }

    case REMOVE_TASK_FROM_UI:
    case REMOVE_EVENT_WITH_TASK_FROM_UI: {
      let { id, tempId } = action;
      id = id || tempId;
      let tasksObj = { ...state.tasksObj };
      delete tasksObj[id];
      let inProgressObj = { ...state.inProgressObj };
      delete inProgressObj[id];

      return {
        ...state,
        tasksObj,
        inProgressObj,
      };
    }

    case ADD_EVENT_WITH_TASK_FAILED:
    case ADD_TASK_FAILED: {
      let { tempId } = action;
      let tasksObj = { ...state.tasksObj };
      delete tasksObj[tempId];
      return {
        ...state,
        tasksObj,
      };
    }
    case ADD_EVENT_WITH_TASK_SUCCESS:
    case ADD_TASK_SUCCESS: {
      let { id, tempId, eventId, taskId, clearInProgress } = action;
      id = id || taskId;
      let tasksObj = { ...state.tasksObj };
      let task = {
        ...tasksObj[tempId],
        id,
      };
      if (eventId) {
        task.eventId = eventId;
      }
      let inProgressObj = { ...state.inProgressObj };
      delete inProgressObj[tempId];
      if (!clearInProgress) inProgressObj[id] = true;

      delete tasksObj[tempId];
      tasksObj[id] = task;
      return {
        ...state,
        tasksObj,
        inProgressObj,
      };
    }

    case PUT_EVENT_TO_UI: {
      let { id, taskId, beginDate, endDate } = action;
      if (!taskId) return state;

      let tasksObj = {
        ...state.tasksObj,
        [taskId]: {
          ...state.tasksObj[taskId],
          eventId: id,
          eventBeginDate: beginDate,
          eventEndDate: endDate,
        },
      };
      return {
        ...state,
        tasksObj,
      };
    }
    case ADD_EVENT_FAILED: {
      let { taskId } = action;

      let task = { ...state.tasksObj[taskId] };
      delete task.eventId;
      delete task.eventBeginDate;
      delete task.eventEndDate;
      delete task.inProgress;

      let tasksObj = {
        ...state.tasksObj,
        [taskId]: task,
      };
      return {
        ...state,
        tasksObj,
      };
    }

    case SET_TASK_DUE_DATE: {
      const { taskId, dueDate } = action;
      return {
        ...state,
        tasksObj: {
          ...state.tasksObj,
          [taskId]: {
            ...state.tasksObj[taskId],
            dueDate,
          },
        },
      };
    }

    case SET_TASK_RECURRENCE: {
      const { taskId, recurrence } = action;
      return {
        ...state,
        tasksObj: {
          ...state.tasksObj,
          [taskId]: {
            ...state.tasksObj[taskId],
            recurrence,
          },
        },
      };
    }

    // case 'UPDATE_TASK_PREDICTIONS': {
    //   const { predictionsObj } = action;
    //   return {
    //     ...state,
    //     predictionsByTaskId: {
    //       ...state.predictionsByTaskId,
    //       ...predictionsObj,
    //     },
    //   };
    // }

    case ADD_EVENT_SUCCESS:
    default:
      return state;
  }
};

const checkTodoistVisibilitySettings = (task, user) => {
  if (!user.accounts.todoist || !task.todoist) return true;

  const { userId, hideUnassigned, hideAssignedToOthers, hideWithoutDueDate } = user.accounts.todoist;
  const assignedTo = task.todoist.responsible_uid;
  const dueDate = task.todoist.due;

  if (hideUnassigned && !assignedTo) return false;
  if (hideAssignedToOthers && assignedTo && assignedTo !== userId) return false;
  if (hideWithoutDueDate && !dueDate) return false;

  return true;
};

export const getVisibleTasksWithLists = createSelector<
  ReduxState,
  ReduxState['tasks']['tasksObj'],
  ReduxState['tasks']['listsObj'],
  ReduxState['account']['user'],
  Task[]
>(
  [(state) => state.tasks.tasksObj, (state) => state.tasks.listsObj, (state) => state.account.user],
  (tasksObj, listsObj, user) =>
    ObjectValues(tasksObj).filter(
      (task) => listsObj[task.listId] && listsObj[task.listId].enabled && checkTodoistVisibilitySettings(task, user)
    )
);

export const getOverdueTasks = createSelector<ReduxState, Task[], Task[]>([getVisibleTasksWithLists], (tasks) => {
  return tasks.filter((task) => isOverdue(task));
});

export const getTasksForList = createSelector(
  [getVisibleTasksWithLists, (_, selectedListId) => selectedListId],
  (tasks, selectedListId) => {
    console.log('Selector - filter tasks for list', selectedListId);
    return tasks.filter((task) => task.listId === selectedListId);
  }
);

export const applyVisibilityFilters = (
  tasks: Task[],
  filter: ReduxState['ui']['filter'],
  preservedVisibleTasks?: Record<string, Task | boolean>,
  user?: User
) => {
  return tasks.filter((task) => {
    if (filter.showOnlyUserTasks && !!user && task.assignedTo !== user.email) {
      return false;
    }

    if (task.recurringEventIds && !task.nextInstEventId) return false; // TEMP hide recurring event-tasks

    if (filter.showOverdueOnly) {
      if (task.completed || !isOverdue(task)) return false;
      return true;
    }

    if (task.completed) {
      if (filter.showCompleted || preservedVisibleTasks?.[task.id]) return true;
      return false;
    }

    if (filter.showScheduled || !(task.eventId || task.nextInstEventId) || isOverdue(task)) {
      return true;
    }
    return false;
  });
};

const tasksWithFilters = createSelector(
  [getVisibleTasksWithLists, (state) => state.ui.filter, (state) => state.account.user],
  (tasks: Task[], filter: ReduxState['ui']['filter'], user: ReduxState['account']['user']) => {
    return applyVisibilityFilters(tasks, filter, undefined, user);
  }
);
const byDue = (l, r) => {
  const ld = l.dueDate; //.getDate()
  const rd = r.dueDate; //.getDate()
  if (ld === rd) return 0;
  return ld < rd ? -1 : 1;
};
export const getTasksForNextXDays = createSelector(
  [tasksWithFilters, (_, upperDueDate) => upperDueDate, (_, __, lowerDueDate) => lowerDueDate],
  (tasks, upperDueDate, lowerDueDate) => {
    console.log('Selector - tasks with due till', upperDueDate, 'after', lowerDueDate);
    // let date = moment(upperDueDate).toDate().getTime()

    return tasks
      .filter((task) => {
        if (!task.dueDate) return false;
        // return (new Date(task.dueDate).getTime() <= date)
        // console.log('DUE!!!', task.dueDate, upperDueDate, lowerDueDate, task);
        const momentDue = moment(task.dueDate);

        return momentDue.isSameOrBefore(upperDueDate) && (!lowerDueDate || momentDue.isAfter(lowerDueDate));
        // return task.dueDate <= upperDueDate && (!lowerDueDate || task.dueDate > lowerDueDate);
      })
      .sort(byDue);
  }
);

export const getTasksForScheduleQueue = createSelector(
  [getVisibleTasksWithLists, (state) => state.tasks.listsObj, (state) => state.account.user.prefs],
  (tasks, listsObj, prefs) => {
    const ctx = {
      momenttz: moment,
      prefs,
    };
    return tasks
      .filter((t) => !t.completed && !t.eventId && !t.recurringEventIds)
      .filter((t) => inSchedulingQueue(ctx, t, listsObj))
      .sort((l, r) => {
        const ld = l.dueDate || '9999-12-31';
        const rd = r.dueDate || '9999-12-31';
        if (ld === rd) return 0;
        return ld < rd ? -1 : 1;
      });
  }
);

const byOrder = (l, r) => {
  // console.log('compare', l, r)
  if (l.order === r.order) return 0;
  if (l.order === undefined) return -1;
  if (r.order === undefined) return 1;
  return +l.order - r.order;
};

const byItemOrder = (l, r) => {
  // console.log('compare', l, r)
  if (l.item_order === r.item_order) return 0;
  if (l.item_order === undefined) return -1;
  if (r.item_order === undefined) return 1;
  return +l.item_order - r.item_order;
};

export const getEnabledLists = createSelector<ReduxState, ReduxState['tasks']['listsObj'], List[]>(
  [(state) => state.tasks.listsObj],
  (listsObj) =>
    ObjectValues(listsObj)
      .filter((list) => list.enabled)
      .sort(byOrder)
);

export const getAllLists = createSelector<ReduxState, ReduxState['tasks']['listsObj'], List[]>(
  [(state) => state.tasks.listsObj],
  (listsObj) => ObjectValues(listsObj).sort(byOrder)
);

export const getDefaultListId = createSelector<ReduxState, ReduxState['tasks']['defaultListId'], string>(
  [(state) => state.tasks.defaultListId],
  (defaultListId) => defaultListId
);

export const getFilters = createSelector<ReduxState, ReduxState['tasks']['filtersObj'], Filter[]>(
  [(state) => state.tasks.filtersObj],
  (filtersObj) =>
    ObjectValues(filtersObj)
      .filter((f) => !f.removed)
      .sort(byItemOrder)
);

export const getPendingInvitations = createSelector<ReduxState, ReduxState['account']['user'], Invitation[]>(
  [(state) => state.account.user],
  (user) => user.invitations.received.filter((inv) => inv.status === 'pending')
);

export const getRecurringEventIdsToTaskIds = createSelector<
  ReduxState,
  ReduxState['tasks']['tasksObj'],
  { [recurringEventId: string]: string }
>([(state) => state.tasks.tasksObj], (tasksObj) =>
  ObjectValues(tasksObj).reduce((acc, task) => {
    if (!task.completed && !task.removed && typeof task.recurringEventIds === 'object') {
      Object.keys(task.recurringEventIds).forEach((id) => {
        acc[id] = task.id;
      });
    }
    return acc;
  }, {})
);

export const getRecurringTaskEventsCount = createSelector<
  ReduxState,
  ReduxState['tasks']['tasksObj'],
  { [recurringEventId: string]: string },
  number
>([(state) => state.tasks.tasksObj, getRecurringEventIdsToTaskIds], (tasksObj, recurringEventIdsToTaskIds) => {
  const recurringTaskEventIds = Object.values(recurringEventIdsToTaskIds);
  const recurringTaskEventIdsSet = new Set(recurringTaskEventIds);
  return recurringTaskEventIdsSet.size;
});
