import React, { useState } from 'react';
import * as utils from 'common/utils';
import { Button, MenuItem, TextField, Select, FormControl, Stack } from '@mui/material';

const EditListForm = ({ list, onSave, onCancel }) => {
  const [name, setName] = useState(list?.name);
  const [colorId, setColorId] = useState(list?.colorId || 'default');
  let color = utils.colorIdToRGB(colorId);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSave({ name, colorId });
      }}
    >
      <Stack spacing={2}>
        <Stack spacing={2} direction="row">
          <FormControl sx={{ s: 9 }}>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="LIST TITLE"
              variant="standard"
              required
              autoFocus
            />
          </FormControl>
          <FormControl sx={{ s: 3 }}>
            <Select
              value={colorId}
              variant="standard"
              onChange={(e) => setColorId(e.target.value)}
              style={{ color: color }}
            >
              {Object.keys(utils.listColors).map((colorId) => (
                <MenuItem key={colorId} value={colorId} style={{ color: utils.listColors[colorId].rgb }}>
                  {utils.listColors[colorId].display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack spacing={2} direction="row">
          <Button variant="contained" type="submit" color="primary">
            Create List
          </Button>
          <Button variant="text" onClick={() => onCancel()}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default EditListForm;
