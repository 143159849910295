import moment from 'moment';
import { ChatActionNewTaskEvent, ChatActionTaskEvent, ChatMessageRichData } from './types';

export const taskTitleWithList = (title: string, listName: string) => {
  return `${listName}: ${title}`;
};

export interface ParseChatRichMessageResult {
  data: ChatMessageRichData;
  isTextOnly: boolean;
  isDocsOnly: boolean;
  isGetOnly: boolean;
  hasActions: boolean;
  isInvalid: boolean;
}

export const parseChatRichMessage = (message: string, validFields?: string[]): ParseChatRichMessageResult => {
  let data: ChatMessageRichData = {
    text: message,
  };

  try {
    data = JSON.parse(message);
    if (typeof data !== 'object') {
      console.error('Invalid rich message', message);
      data = {
        text: message,
      };
    }
  } catch (e) {}

  const itemsCount = Object.keys(data).length;
  const isTextOnly = typeof data.text === 'string' && itemsCount === 1;
  const isDocsOnly = !!data.docs && itemsCount === 1;
  const isGetOnly = !!data.get && itemsCount === 1;
  const hasActions = !isTextOnly;

  const isInvalid = !validFields || Object.keys(data).some((key) => !validFields.includes(key));

  return {
    data,
    isTextOnly,
    isDocsOnly,
    isGetOnly,
    hasActions,
    isInvalid,
  };
};

const pad = (num: number) => (num < 10 ? `0${num}` : num);
const reLocalWithoutTimezone = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})$/;

export const localToUTC = (date: string, timezoneOffset: number): moment.Moment => {
  if (!reLocalWithoutTimezone.test(date)) return moment(date);

  const offsetHour = Math.floor(timezoneOffset / 60);
  const offsetMin = timezoneOffset % 60;
  const offsetHourMinStr = `${offsetHour >= 0 ? '+' : '-'}${pad(Math.abs(offsetHour))}:${pad(Math.abs(offsetMin))}`;
  return moment(date + offsetHourMinStr);
};

export const encrichWithEndDate = (events: (ChatActionNewTaskEvent | ChatActionTaskEvent)[]) => {
  return events.map((event) => {
    const { beginDate, endDate, allDay, duration } = event;
    if (!beginDate || endDate) return event;
    if (allDay || !duration) {
      return { ...event, allDay: true, endDate: moment(beginDate).add(1, 'day').toISOString() };
    }
    return {
      ...event,
      allDay: allDay || duration >= 1440,
      endDate: moment(beginDate).add(duration, 'minutes').toISOString(),
    };
  });
};

export const convertDatesToUTC = <T extends { beginDate: string; endDate?: string }>(
  events: T[],
  timezoneOffset: number
): T[] => {
  return events.map((event) => {
    const { beginDate } = event;
    if (!beginDate) return event;
    const res = { ...event, beginDate: localToUTC(beginDate, +timezoneOffset).toISOString() };
    if (event.endDate) {
      res.endDate = localToUTC(event.endDate, +timezoneOffset).toISOString();
    }
    return res;
  });
};

export const ChatSystemInfoDelimiter = '---';
